<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card bg-pattern">
                    <div class="card-body p-4">
                        <div class="text-center w-75 m-auto">
                            <span>
                                <img
                                    src="@assets/images/logo.svg"
                                    alt
                                    height="50"
                                />
                            </span>
                            <p class="text-muted mb-4 mt-3">
                                Enter your email address and password to access
                                admin panel.
                            </p>
                        </div>

                        <b-form @submit.prevent="tryToLogIn">
                            <b-form-group
                                id="input-group-1"
                                label="E-mail"
                                label-for="input-1"
                            >
                                <b-form-input
                                    id="input-1"
                                    v-model="email"
                                    type="email"
                                    required
                                    placeholder="Enter email"
                                />
                            </b-form-group>

                            <b-form-group
                                id="input-group-2"
                                label="Password"
                                label-for="input-2"
                            >
                                <b-form-input
                                    id="input-2"
                                    v-model="password"
                                    type="password"
                                    required
                                    placeholder="Enter password"
                                />
                            </b-form-group>

                            <b-alert
                                v-model="isAuthError"
                                variant="danger"
                                dismissible
                            >
                                Wrong credentials!
                            </b-alert>

                            <b-form-group id="button-group" class="mt-4">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    class="btn-block"
                                >
                                    Log In
                                </b-button>
                            </b-form-group>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="row mt-3">
                    <div class="col-12 text-center">
                        <p>
                            <router-link
                                to="/forget-password"
                                class="text-white-50 ml-1"
                            >
                                Forgot your password?
                            </router-link>
                        </p>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>

<script>
import config from '@config';
import { authMethods } from '@state/helpers';

export default {
    page: {
        title: 'Login',
        meta: [{ name: 'description', content: `Log in to ${config.title}` }]
    },

    data() {
        return {
            email: '',
            password: '',
            isAuthError: false,
            tryingToLogIn: false
        };
    },

    methods: {
        ...authMethods,
        async tryToLogIn() {
            this.tryingToLogIn = true;
            // Reset the authError if it existed.
            this.authError = null;

            try {
                await this.login({
                    email: this.email,
                    password: this.password
                });

                this.tryingToLogIn = false;
                this.isAuthError = false;

                // Redirect to the originally requested page, or to the home page
                const redirectRoute =
                    this.$route.query.redirectFrom &&
                    this.$route.query.redirectFrom !== '/logout'
                        ? this.$route.query.redirectFrom
                        : { name: 'dashboard' };

                this.$router.push(redirectRoute);
            } catch (error) {
                console.error(error);

                if (error.response.status === 401) {
                    this.isAuthError = true;

                    return;
                }

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
};
</script>
